import { BASE_URL } from '@constants/api'
import { ListLeadRequest, ListLeadResponse } from '@models/leads.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const leadApi = createApi({
	reducerPath: 'leadApi',
	tagTypes: ['Usuarios', 'Usuario'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<ListLeadResponse, ListLeadRequest>({
			query: ({ page, startDate, endDate }) => ({
				url: `/leads?limit=20&page=${page}&startDate=${startDate}&endDate=${endDate}`,
				method: 'GET',
			}),
			providesTags: ['Usuarios'],
		}),
		show: builder.mutation<{ id: string }, { id: string }>({
			query: ({ id }) => ({
				url: `/admins/${id}`,
				method: 'GET',
			}),
		}),
	}),
})
